import { Flex, Box } from "@chakra-ui/react";
import LayoutHoc from "../../components/layout";
import { FaqAccordion } from "../../components/Accordion/accordion";
import { Calculator } from "../../components/Calculator";
import { DismantlingCarHero } from "../../components/DismantlingCarHero";
import { DismantlingSplitLayout } from "../../components/DismantlingSplitLayout";
import { ContactUs } from "../../components/ContactUs";
import { WeOffer } from "../../components/WeOffer";
import { useRef } from "react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

const DismantlingCar = () => {
  const calculatorRef = useRef();

  const scrollToCalc = () => {
    calculatorRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return (
    <LayoutHoc spacing={0}>
      <Flex width={"100%"} overflow={"hidden"} flexDirection={"column"}>
        <DismantlingCarHero scrollToSection={scrollToCalc} />
        <Flex
          paddingRight={{ base: 0, md: 12, xl: 24 }}
          paddingLeft={{ base: 0, md: 12, xl: 24 }}
          maxW="8xl"
          width={"full"}
          alignItems={"center"}
          flexDirection={"column"}
          marginLeft={"auto"}
          marginRight={"auto"}
        >
          <WeOffer />
        </Flex>
        <DismantlingSplitLayout />
        <Box
          maxW="8xl"
          paddingRight={{ base: 6, md: 12, xl: 24 }}
          paddingLeft={{ base: 6, md: 12, xl: 24 }}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={14}
          width={"100%"}
          ref={calculatorRef}
        >
          <h1 className="text-center mb-11 text-3xl font-Mtavruli">
            კალკულატორი
          </h1>
          <Calculator />
        </Box>
        <div className="mt-16">
          <ContactUs />
        </div>
        {/* FAQ */}
        <Box
          maxW="8xl"
          paddingRight={{ base: 6, md: 12, xl: 24 }}
          paddingLeft={{ base: 6, md: 12, xl: 24 }}
          marginLeft={"auto"}
          marginRight={"auto"}
          marginTop={20}
        >
          <h2 className="text-center mb-11 text-3xl font-Mtavruli">
            ხშირად დასმული კითხვები
          </h2>
          <div className="border-black border-2 rounded-2xl border-opacity-25 mb-24">
            <FaqAccordion />
          </div>
        </Box>
      </Flex>
    </LayoutHoc>
  );
};

export default DismantlingCar;
