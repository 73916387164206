import {
  Box,
  Flex,
  HStack,
  Button,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import Flags from "../flags";
import Logo from "../../assets/greenAutoImportLogo-removebg-preview.png";

const NavLink = ({ link, handleNavigation, children }) => {
  return (
    <Button
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("gray.200", "gray.700"),
      }}
      id={link}
      onClick={handleNavigation}
    >
      {children}
    </Button>
  );
};

NavLink.propTypes = {
  link: PropTypes.string,
  handleNavigation: PropTypes.func,
  children: PropTypes.node,
};

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleLogo = () => {
    if (location.pathname !== "/") {
      navigate("/");
    }
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleNavigation = useCallback(
    ({ currentTarget }) => {
      if (location.pathname !== currentTarget.id) {
        navigate(currentTarget.id);
      }
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    },
    [location.pathname, navigate]
  );

  const Links = [
    { name: t("home"), path: "/" },
    { name: t("aboutUs"), path: "/about-us" },
    { name: t("contact"), path: "/contact" },
    { name: t("autoParts"), path: "/auto-parts" },
    { name: t("rental"), path: "/rental" },
    { name: t("dismantlingCar"), path: "/dismantling-car" },
  ];

  return (
    <Box
      position="sticky"
      top="0"
      zIndex={100}
      bg={useColorModeValue("gray.100", "gray.900")}
      px={4}
    >
      <Flex
        h={16}
        alignItems="center"
        justifyContent={{ base: "space-between", lg: "center" }}
      >
        <Box
          cursor="pointer"
          onClick={handleLogo}
          position="absolute"
          left={{ base: undefined, lg: 0 }}
          right={{ base: 0, lg: undefined }}
          width="fit-content"
        >
          <img
            alt="logo"
            src={Logo}
            style={{
              height: "50px",
              width: "150px",
              objectFit: "cover",
            }}
          />
        </Box>
        <IconButton
          size={"md"}
          icon={
            isOpen ? (
              <AiOutlineClose size="25px" />
            ) : (
              <AiOutlineMenu size="25px" />
            )
          }
          aria-label={"Open Menu"}
          display={{ base: "flex", lg: "none" }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={"center"}>
          <HStack as={"nav"} spacing={4} display={{ base: "none", lg: "flex" }}>
            {Links.map((link) => (
              <NavLink
                key={link.name}
                handleNavigation={handleNavigation}
                link={link.path}
              >
                {link.name}
              </NavLink>
            ))}
          </HStack>
        </HStack>
      </Flex>

      {isOpen && (
        <Box pb={4} display={{ lg: "none" }}>
          <Stack as={"nav"} spacing={4}>
            {Links.map((link) => (
              <NavLink
                key={link.name}
                handleNavigation={handleNavigation}
                link={link.path}
              >
                {link.name}
              </NavLink>
            ))}
            <Flags
              backgroundColor="none"
              border="none"
              position="relative"
              width="full"
              right="none"
              justifyContent="center"
              top="none"
            />
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default Header;
