export const AdvantageCard = ({ img, title, description }) => {
  return (
    <div className="md:shadow-md border rounded-2xl flex p-4 items-center gap-3 flex-col h-full bg-[#f0f0f0] md:flex-row hover:md:scale-110 hover:md:shadow-2xl">
      <img src={img} className="w-20 h-20" alt="icon" />
      <div className="text-center md:text-start">
        <p className="font-black mb-2 font-Mtavruli">{title}</p>
        <p className="leading-5">{description}</p>
      </div>
    </div>
  );
};
