import { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import vid1 from "../../assets/splitMedia/vid1.mp4";
import vid2 from "../../assets/splitMedia/vid2.mp4";
import vid3 from "../../assets/splitMedia/vid3.mp4";
import vid4 from "../../assets/splitMedia/vid4.mp4";
import vid5 from "../../assets/splitMedia/vid5.mp4";
import vid6 from "../../assets/splitMedia/vid6.mp4";
import vid7 from "../../assets/splitMedia/vid7.mp4";

import img1 from "../../assets/splitMedia/img1.jpeg";
import img2 from "../../assets/splitMedia/img2.jpeg";
import vid1Img from "../../assets/splitMedia/vid1img.png";
import vid2Img from "../../assets/splitMedia/vid2img.png";
import vid3Img from "../../assets/splitMedia/vid3img.png";
import vid4Img from "../../assets/splitMedia/vid4img.png";
import vid5Img from "../../assets/splitMedia/vid5img.png";
import vid6Img from "../../assets/splitMedia/vid6img.png";
import vid7Img from "../../assets/splitMedia/vid7img.png";

const SWIPERDATA = [
  {
    type: "vid",
    src: vid1,
    poster: vid1Img,
  },
  {
    type: "vid",
    src: vid2,
    poster: vid2Img,
  },
  {
    type: "vid",
    src: vid3,
    poster: vid3Img,
  },
  {
    type: "vid",
    src: vid4,
    poster: vid4Img,
  },
  {
    type: "vid",
    src: vid5,
    poster: vid5Img,
  },
  {
    type: "vid",
    src: vid6,
    poster: vid6Img,
  },
  {
    type: "vid",
    src: vid7,
    poster: vid7Img,
  },
  {
    type: "img",
    src: img1,
  },
  {
    type: "img",
    src: img2,
  },
];

export const DismantlingSplitLayout = () => {
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState({});
  const [progress, setProgress] = useState({});

  const togglePlayPause = (index) => {
    const video = videoRefs.current[index];
    if (!video) return;

    if (video.paused) {
      video.play();
      setIsPlaying((prev) => ({ ...prev, [index]: true }));
    } else {
      video.pause();
      setIsPlaying((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleVideoEnd = (index) => {
    setIsPlaying((prev) => ({ ...prev, [index]: false }));

    const nextIndex = index + 1;
    const nextSlide = videoRefs.current[nextIndex];
    if (nextSlide) {
      nextSlide.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTimeUpdate = (index) => {
    const video = videoRefs.current[index];
    if (!video) return;

    const progressValue = (video.currentTime / video.duration) * 100;
    setProgress((prev) => ({ ...prev, [index]: progressValue }));
  };

  const handleSeek = (index, event) => {
    const video = videoRefs.current[index];
    const rect = event.target.getBoundingClientRect();
    const clickX = event.clientX - rect.left;
    const width = rect.width;
    const seekTime = (clickX / width) * video.duration;
    video.currentTime = seekTime;
  };

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    SWIPERDATA.forEach((item, index) => {
      if (item.type === "vid" && videoRefs.current[index]) {
        videoRefs.current[index].src = index === activeIndex ? item.src : "";
      }
    });
  };

  return (
    <div className="mt-32 xl:flex p-0 py-12  xl:p-24 bg-gradient-to-b from-[#5eff5811] to-[#00000005]">
      <div className="grid-cols-2 box-border gap-6 xl:grid items-center">
        <div className="px-6 sm:px-12 xl:px-0">
          <h2 className=" text-4xl font-Mtavruli mb-4 font-black">
            ავტომობილების{" "}
            <span className="text-[#255F23]">დაჭრის სერვისი </span>
            ამერიკის შეერთებულ შტატებში
          </h2>
          <p className="w-full text-base leading-6 md:text-xl font-Arial my-8 xl:px-0">
            დაჭრის ძირითადი პრინციპების გარდა, მომხმარებლის მოთხოვნის
            შესაბამისად შეგვიძლია მოვახდინოთ კონკრეტული ავტომობილის
            დაჭრა-დანაწევრება, რაც უზრუნველყოფს მომხმარებლისთვის მაქსიმალური
            კომფორტის შექმნას.
          </p>
          <div className="w-full h-2 bg-[#255F23] hidden xl:block" />
        </div>
        <div className="flex">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={handleSlideChange}
            modules={[Navigation]}
            navigation
            loop
            className="xl:rounded-2xl h-fit xl:my-auto"
          >
            {SWIPERDATA.map((el, index) => {
              return (
                <SwiperSlide
                  key={index}
                  className="!h-auto !flex bg-[#66666623]"
                >
                  {el.type === "img" ? (
                    <img
                      src={el.src}
                      alt="swiper"
                      className="mx-auto h-full max-h-[500px]"
                    />
                  ) : (
                    <div className="relative m-auto">
                      <video
                        preload="metadata"
                        playsInline
                        poster={el.poster}
                        className="w-full h-full my-auto max-h-[500px]"
                        ref={(el) => (videoRefs.current[index] = el)}
                        onEnded={() => handleVideoEnd(index)}
                        onTimeUpdate={() => handleTimeUpdate(index)}
                        autoPlay={isPlaying[index]}
                        controls={false}
                      >
                        <source src={el.src} type="video/mp4" />
                      </video>

                      <div className="absolute inset-0 flex items-center justify-center">
                        <button
                          onClick={() => togglePlayPause(index)}
                          className="bg-black bg-opacity-50 text-white py-2 px-2 rounded-full"
                        >
                          {isPlaying[index] ? "⏸" : "▶️"}
                        </button>
                      </div>
                      <div
                        className="absolute bottom-0 left-0 right-0 h-2 bg-gray-200"
                        onClick={(e) => handleSeek(index, e)}
                      >
                        <div
                          className="h-2 bg-green-500"
                          style={{ width: `${progress[index] || 0}%` }}
                        ></div>
                      </div>
                    </div>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
