import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18next.config";
import Home from "./Pages/home/Home";
import Contact from "./Pages/contact/contact";
import AboutUs from "./Pages/aboutUs/aboutUs";
import Page404 from "./Pages/404/page404";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AutoParts from "./Pages/autoParts/autoParts";
import SingleVehicleParts from "./Pages/autoParts/singleVehicleParts/singleVehicleParts";
import SignlePart from "./Pages/autoParts/singleVehicleParts/singlePart/singlePart";
import Rental from "./Pages/rental/rental";
import DismantlingCar from "./Pages/dismantlingCar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider>
        <Routes>
          <Route exect path="/" element={<Home />} />
          <Route exect path="/contact" element={<Contact />} />
          <Route exect path="/about-us" element={<AboutUs />} />
          <Route exect path="/auto-parts" element={<AutoParts />} />
          <Route
            path="/auto-parts/:vehicleName"
            element={<SingleVehicleParts />}
          />
          <Route
            path="/auto-parts/:vehicleName/:partName"
            element={<SignlePart />}
          />
          <Route path="/rental" element={<Rental />} />
          <Route path="/dismantling-car" element={<DismantlingCar />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
        <ToastContainer />
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
