import { Box, Image } from "@chakra-ui/react";
import waImg from "../assets/slider/WhatsApp Image 2023-12-04 at 14.42.08 (2).jpeg";

const Slide = () => {
  return (
    <Box
      alignSelf="center"
      position={"relative"}
      height={{ base: "340px", md: "700px" }}
      width={"full"}
      maxW="7xl"
      overflow={"hidden"}
      borderRadius={10}
    >
      <Image
        height={{ base: "300px", md: "700px" }}
        width={"100%"}
        position="relative"
        objectFit="cover"
        src={waImg}
      />
    </Box>
  );
};

export default Slide;
