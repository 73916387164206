"use client";

import { AccordionItem } from "./accordionItem";
import React, { useState } from "react";
import Add from "../../assets/svg/add.svg";
import Remove from "../../assets/svg/min.svg";

export const FaqAccordion = () => {
  const canExpandMultipleRows = false;
  const [isOpen, setIsOpen] = useState({});

  const data = [
    {
      title:
        "შემიძლია თუ არა მთლიანი კონტეინერის ჩამოტანა და რა არის კონტეინერის ფასი?",
      description:
        "დიახ შესაძლებელია, სერვისის ფასი ერთ მანქანაზე არის 700$(ჯიპის შემთხვევაში +150$), საშუალოდ კონტეინერი იტევს  10 დან 12 მანქანამდე.",
    },
    {
      title:
        "რომელი ლოკაციებიდან შემიძლია მანქანის დაჭრა-დანაწევრების სერვისის გამოყენება?",
      description:
        "ამ ეტაპზე სერვისის შემოთავაზება შეგვიძლია : ნიუჯერსის და მისი მიმდებარე შტატებიდან, ზუსტი ფასების მისაღებად გთხოვს იხილოთ კალკულატორი.",
    },
    {
      title: "რამდენ ხანში იჭრება და იტვირთება ავტომანქანა?",
      description:
        "აუქციონის წარმატებით დასრულების შემდგომი ეტაპია : მისი გადაზიდვა საწყობის მიმართულებით და შემდგომ დაჭრა-დანაწევრების პროცესის დაწყება რაც საშუალოდ მოიცავს 2 დან 3კვირამდე დროს.",
    },
    {
      title: "როგორ დავითვალო კონკრეტული ფასი აუქციონიდან ფოთის პორტამდე?",
      description:
        "კალკულატორში შეგიძლიათ მიიღოთ კონკრეტული ფასები იმ ლოკაციებიდან რომლიდანაც შეგვიძლია დაჭრის სერვისს შეთავაზება. სამომავლოდ იგეგმება Georgia, Texas ლოკაციების ათვისებაც.",
    },
  ];

  const handleToggle = (index) => {
    if (canExpandMultipleRows) {
      setIsOpen((prevState) => ({ ...prevState, [index]: !prevState[index] }));
    } else {
      setIsOpen((prevState) => ({ [index]: !prevState[index] }));
    }
  };

  return data.map((item, index) => {
    return (
      <AccordionItem
        key={index}
        isOpen={isOpen[index]}
        onClick={() => handleToggle(index)}
        label={item.title}
        content={item.description}
        icon={Add}
        closeIcon={Remove}
      />
    );
  });
};
