import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCoYHD9OXMjHWnxLksj4V17AkCEduqLP4A",
  authDomain: "green-auto-import.firebaseapp.com",
  projectId: "green-auto-import",
  storageBucket: "green-auto-import.appspot.com",
  messagingSenderId: "18150735353",
  appId: "1:18150735353:web:0fadc5e12ca9829e7d5128",
  measurementId: "G-HRY7EGJ934",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
