import PropTypes from "prop-types";

export const CustomInput = ({
  type,
  name,
  placeholder,
  label,
  value,
  onChange,
}) => {
  return (
    <div className="relative z-0 w-full">
      <input
        type={type}
        name={name}
        className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
        placeholder={placeholder}
        onChange={(e) => onChange(e)}
        value={value}
      />
      <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
        {label}
      </label>
    </div>
  );
};

CustomInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
};
