import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowWidth } from "@react-hook/window-size";
import imgCustomClearance from "../../assets/advantageIcons/custom-clearance.png";
import imgTowTruck from "../../assets/advantageIcons/tow-truck.png";
import imgTeam from "../../assets/advantageIcons/partners.png";
import imgWarehouse from "../../assets/advantageIcons/warehouse.png";
import imgPackage from "../../assets/advantageIcons/package.png";
import imgParts from "../../assets/advantageIcons/car-door.png";
import imgGoodPrice from "../../assets/advantageIcons/best-price.png";
import imgAdditional from "../../assets/advantageIcons/arrows.png";
import imgPhoto from "../../assets/advantageIcons/camera.png";
import { AdvantageCard } from "../AdvantageCard";

export const WeOffer = () => {
  const Advantages = [
    {
      id: 0,
      description: "ფოთის ტერმინალზე საბაჟო მომსახურების გაწევას",
      title: "საბაჟო მომსახურეობა",
      img: imgCustomClearance,
    },
    {
      id: 1,
      description: "ტრანსპორტირებას საქართველოს მასშტაბით ნებისმიერ წერტილში",
      title: "ტრანსპორტირება",
      img: imgTowTruck,
    },
    {
      id: 2,
      description: "მაღალ კვალიფიციურ და გამოცდილ გუნდის",
      title: "გამოცდილი გუნდი",
      img: imgTeam,
    },
    {
      id: 3,
      description: "პირად მფლობელობაში არსებულ საწყობს",
      title: "საწყობი",
      img: imgWarehouse,
    },
    {
      id: 4,
      description:
        "დაჭრა-დანაწევრებას დანადგარის გარეშე, რომელიც უზრუნველყოფს დაზიანების თავიდან აცილებას",
      title: "ავტომანქანის დაჭრა-დანაწევრება",
      img: imgParts,
    },
    {
      id: 5,
      description:
        "ტვირთის გადაფუთვასა და დასაწყობებას ყველანაირი სტანდარტის დაცვით",
      title: "ტვირთის გადაფუთვა",
      img: imgPackage,
    },
    {
      id: 6,
      description: "ბაზარზე არსებულ ფასებთან შედარებით ყველაზე ხელსაყრელ ფასს",
      title: "ხელსაყრელი ფასი",
      img: imgGoodPrice,
    },
    {
      id: 7,
      description:
        "არაკონსოლიდირებული ტვირთის შემთხვევაში დამატებითი შეღავათებს",
      title: "არაკონსოლიდირებული ტვირთი",
      img: imgAdditional,
    },
    {
      id: 8,
      description: "დანაწევრებული დეტალების დეტალურ დასურათებას",
      title: "დეტალების დასურათება",
      img: imgPhoto,
    },
  ];
  const onlyWidth = useWindowWidth();
  return (
    <>
      <h1 className="text-center text-3xl mb-6 mt-14 md:mt-28 font-Mtavruli">
        ჩვენ გთავაზობთ
      </h1>
      {onlyWidth > 767 ? (
        <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-6">
          {Advantages.map((item) => {
            return (
              <AdvantageCard
                key={item.id}
                img={item.img}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full h-full">
          <Swiper
            spaceBetween={10}
            slidesPerView={2.5}
            breakpoints={{
              0: { slidesPerView: 1.5, centeredSlides: true },
              570: { slidesPerView: 2.5 },
            }}
            className="w-full h-full"
          >
            {Advantages.map((item) => {
              return (
                <SwiperSlide key={item.id}>
                  <AdvantageCard
                    img={item.img}
                    title={item.title}
                    description={item.description}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};
