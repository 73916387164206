import { CustomSelect } from "../UI/Select";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import "./index.css";

const AUCTIONOPTIONS = [
  { value: "Copart", label: "Copart" },
  { value: "IAAI", label: "IAAI" },
];

const AUCTIONPRICES = [
  { value: "Copart", price: 0 },
  { value: "auction2", price: 0 },
];
const CAROPTIONS = [
  { value: "jeep", label: "ჯიპი" },
  { value: "sedan", label: "სედანი" },
];

const CARPRICES = [
  { value: "jeep", price: 150 },
  { value: "sedan", price: 0 },
];

const LOCATIONOPTIONS = [
  { value: "CT - Hartford", label: "CT - Hartford" },
  { value: "CT - Hartford Springfield", label: "CT - Hartford Springfield" },
  { value: "DE - Seaford", label: "DE - Seaford" },
  { value: "IL - Chicago North", label: "IL - Chicago North" },
  { value: "IL - Peoria", label: "IL - Peoria" },
  { value: "IL - Chicago South", label: "IL - Chicago South" },
  { value: "IL - Wheeling", label: "IL - Wheeling" },
  { value: "IL - Southern Illinois", label: "IL - Southern Illinois" },
  { value: "IN - Indianapolis", label: "IN - Indianapolis" },
  { value: "IN - Cicero", label: "IN - Cicero" },
  { value: "IN - Fort Wayne", label: "IN - Fort Wayne" },
  { value: "IN - Dyer", label: "IN - Dyer" },
  { value: "KS - Kansas City East", label: "KS - Kansas City East" },
  { value: "KS - Wichita", label: "KS - Wichita" },
  { value: "KS - Kansas City", label: "KS - Kansas City" },
  { value: "ME - Lyman", label: "ME - Lyman" },
  { value: "DC - Washington DC", label: "DC - Washington DC" },
  { value: "MD - Baltimore", label: "MD - Baltimore" },
  { value: "MD - Baltimore East", label: "MD - Baltimore East" },
  { value: "MA - South Boston", label: "MA - South Boston" },
  { value: "MA - North Boston", label: "MA - North Boston" },
  { value: "MA - West Warren", label: "MA - West Warren" },
  { value: "MA - Freetown", label: "MA - Freetown" },
  {
    value: "International Offsite Auction",
    label: "International Offsite Auction",
  },
  { value: "Ml - Detroit", label: "Ml - Detroit" },
  { value: "Ml - Lansing", label: "Ml - Lansing" },
  { value: "Ml - Flint", label: "Ml - Flint" },
  { value: "Ml - Ionia", label: "Ml - Ionia" },
  { value: "Ml - Kincheloe", label: "Ml - Kincheloe" },
  { value: "MN - Minneapolis", label: "MN - Minneapolis" },
  { value: "MN - St. Cloud", label: "MN - St. Cloud" },
  { value: "MN - Minneapolis North", label: "MN - Minneapolis North" },
  { value: "NH - Candia", label: "NH - Candia" },
  { value: "NJ - Glassboro East", label: "NJ - Glassboro East" },
  { value: "NJ - Glassboro West", label: "NJ - Glassboro West" },
  { value: "NJ - Somerville", label: "NJ - Somerville" },
  { value: "NJ - Trenton", label: "NJ - Trenton" },
  { value: "NM - Albuquerque", label: "NM - Albuquerque" },
  { value: "NY - Newburgh", label: "NY - Newburgh" },
  { value: "NY - Syracuse", label: "NY - Syracuse" },
  { value: "NY - Long Island", label: "NY - Long Island" },
  { value: "NY - Rochester", label: "NY - Rochester" },
  { value: "NY - Albany", label: "NY - Albany" },
  { value: "NY - Buffalo", label: "NY - Buffalo" },
  { value: "OH - Columbus", label: "OH - Columbus" },
  { value: "OH - Cleveland East", label: "OH - Cleveland East" },
  { value: "OH - Cleveland West", label: "OH - Cleveland West" },
  { value: "OH - Dayton", label: "OH - Dayton" },
  { value: "PA - Philadelphia", label: "PA - Philadelphia" },
  { value: "PA - Pittsburgh North", label: "PA - Pittsburgh North" },
  { value: "PA - Harrisburg", label: "PA - Harrisburg" },
  { value: "PA - Pittsburgh South", label: "PA - Pittsburgh South" },
  { value: "PA - York Haven", label: "PA - York Haven" },
  { value: "PA - Chambersburg", label: "PA - Chambersburg" },
  { value: "PA - Altoona", label: "PA - Altoona" },
  { value: "PA - Scranton", label: "PA - Scranton" },
  { value: "PA - Philadelphia East", label: "PA - Philadelphia East" },
  { value: "PA - Pittsburgh West", label: "PA - Pittsburgh West" },
  { value: "RI - Exeter", label: "RI - Exeter" },
  { value: "VA - Danville", label: "VA - Danville" },
  { value: "VA - Richmond East", label: "VA - Richmond East" },
  { value: "VA - Richmond", label: "VA - Richmond" },
  { value: "VA - Hampton", label: "VA - Hampton" },
  { value: "VA - Fredericksburg", label: "VA - Fredericksburg" },
  { value: "WV - Charleston", label: "WV - Charleston" },
  { value: "WI - Milwaukee", label: "WI - Milwaukee" },
  { value: "WI - Appleton", label: "WI - Appleton" },
  { value: "WI - Madison South", label: "WI - Madison South" },
  { value: "WI - Milwaukee North", label: "WI - Milwaukee North" },
  { value: "WI - Milwaukee South", label: "WI - Milwaukee South" },
  { value: "CT - Hartford South", label: "CT - Hartford South" },
  { value: "DE - New Castle", label: "DE - New Castle" },
  { value: "IL - Chicago West", label: "IL - Chicago West" },
  { value: "IL - Dream Rides", label: "IL - Dream Rides" },
  { value: "IL - Lincoln", label: "IL - Lincoln" },
  {
    value: "IL - REC RIDES Online Exclusive",
    label: "IL - REC RIDES Online Exclusive",
  },
  { value: "IL - Specialty Division", label: "IL - Specialty Division" },
  { value: "IL - St. Louis", label: "IL - St. Louis" },
  { value: "IN - Indianapolis South", label: "IN - Indianapolis South" },
  { value: "IN - South Bend", label: "IN - South Bend" },
  { value: "MA - Boston Shirley", label: "MA - Boston Shirley" },
  { value: "MA - Taunton", label: "MA - Taunton" },
  { value: "MA - Templeton", label: "MA - Templeton" },
  { value: "MD - Dundalk", label: "MD - Dundalk" },
  { value: "MD - Elkton", label: "MD - Elkton" },
  { value: "MD - Metro DC", label: "MD - Metro DC" },
  { value: "ME - Online Exclusive", label: "ME - Online Exclusive" },
  { value: "ME - Portland Gorham", label: "ME - Portland Gorham" },
  { value: "Ml - Grand Rapids", label: "Ml - Grand Rapids" },
  { value: "MN - Minneapolis South", label: "MN - Minneapolis South" },
  { value: "MN - Minneapolis/St. Paul", label: "MN - Minneapolis/St. Paul" },
  { value: "NH - Manchester", label: "NH - Manchester" },
  { value: "NJ - Avenel New Jersey", label: "NJ - Avenel New Jersey" },
  { value: "NJ - Central New Jersey", label: "NJ - Central New Jersey" },
  { value: "NJ - Englishtown", label: "NJ - Englishtown" },
  { value: "NJ - Port Murray", label: "NJ - Port Murray" },
  { value: "NJ - Sayreville", label: "NJ - Sayreville" },
  { value: "NJ - Southern New Jersey", label: "NJ - Southern New Jersey" },
  { value: "OH - Akron Canton", label: "OH - Akron Canton" },
  { value: "OH - Cincinnati", label: "OH - Cincinnati" },
  { value: "OH - Cincinnati South", label: "OH - Cincinnati South" },
  { value: "PA - Bridgeport", label: "PA - Bridgeport" },
  { value: "PA - Erie", label: "PA - Erie" },
  { value: "PA - Pittsburgh", label: "PA - Pittsburgh" },
  { value: "PA - York Springs", label: "PA - York Springs" },
  { value: "RI - Providence", label: "RI - Providence" },
  { value: "VA - Culpeper", label: "VA - Culpeper" },
  { value: "VA - Northern Virginia", label: "VA - Northern Virginia" },
  { value: "VA - Pulaski", label: "VA - Pulaski" },
  { value: "VA - Roanoke", label: "VA - Roanoke" },
  { value: "VA - Suffolk", label: "VA - Suffolk" },
  { value: "VA - Tidewater", label: "VA - Tidewater" },
  { value: "VT - Burlington", label: "VT - Burlington" },
  { value: "WI - Portage", label: "WI - Portage" },
  { value: "WV - Buckhannon", label: "WV - Buckhannon" },
  { value: "WV - Shady Spring", label: "WV - Shady Spring" },
  { value: "MENTONE", label: "MENTONE" },
  { value: "ME - WINDHAM", label: "ME - WINDHAM" },
  { value: "MI WAYLAND", label: "MI WAYLAND" },
];

const LOCATIONPRICES = [
  { value: "CT - Hartford", price: 1100 },
  { value: "CT - Hartford Springfield", price: 1100 },
  { value: "DE - Seaford", price: 1150 },
  { value: "IL - Chicago North", price: 1300 },
  { value: "IL - Peoria", price: 1350 },
  { value: "IL - Chicago South", price: 1300 },
  { value: "IL - Wheeling", price: 1325 },
  { value: "IL - Southern Illinois", price: 1300 },
  { value: "IN - Indianapolis", price: 1425 },
  { value: "IN - Cicero", price: 1450 },
  { value: "IN - Fort Wayne", price: 1400 },
  { value: "IN - Dyer", price: 1300 },
  { value: "KS - Kansas City East", price: 1375 },
  { value: "KS - Wichita", price: 1425 },
  { value: "KS - Kansas City", price: 1375 },
  { value: "ME - Lyman", price: 1225 },
  { value: "DC - Washington DC", price: 1300 },
  { value: "MD - Baltimore", price: 1075 },
  { value: "MD - Baltimore East", price: 1075 },
  { value: "MA - South Boston", price: 1150 },
  { value: "MA - North Boston", price: 1150 },
  { value: "MA - West Warren", price: 1150 },
  { value: "MA - Freetown", price: 1150 },
  { value: "International Offsite Auction", price: 1225 },
  { value: "Ml - Detroit", price: 1425 },
  { value: "Ml - Lansing", price: 1450 },
  { value: "Ml - Flint", price: 1450 },
  { value: "Ml - Ionia", price: 1500 },
  { value: "Ml - Kincheloe", price: 1700 },
  { value: "MN - Minneapolis", price: 1550 },
  { value: "MN - St. Cloud", price: 1550 },
  { value: "MN - Minneapolis North", price: 1550 },
  { value: "NH - Candia", price: 1250 },
  { value: "NJ - Glassboro East", price: 950 },
  { value: "NJ - Glassboro West", price: 900 },
  { value: "NJ - Somerville", price: 950 },
  { value: "NJ - Trenton", price: 950 },
  { value: "NM - Albuquerque", price: 1425 },
  { value: "NY - Newburgh", price: 1050 },
  { value: "NY - Syracuse", price: 1175 },
  { value: "NY - Long Island", price: 1050 },
  { value: "NY - Rochester", price: 1200 },
  { value: "NY - Albany", price: 1050 },
  { value: "NY - Buffalo", price: 1150 },
  { value: "OH - Columbus", price: 1300 },
  { value: "OH - Cleveland East", price: 1300 },
  { value: "OH - Cleveland West", price: 1300 },
  { value: "OH - Dayton", price: 1300 },
  { value: "PA - Philadelphia", price: 1100 },
  { value: "PA - Pittsburgh North", price: 1250 },
  { value: "PA - Harrisburg", price: 1150 },
  { value: "PA - Pittsburgh South", price: 1250 },
  { value: "PA - York Haven", price: 1100 },
  { value: "PA - Chambersburg", price: 1150 },
  { value: "PA - Altoona", price: 1250 },
  { value: "PA - Scranton", price: 1250 },
  { value: "PA - Philadelphia East", price: 1150 },
  { value: "PA - Pittsburgh West", price: 1300 },
  { value: "RI - Exeter", price: 1200 },
  { value: "VA - Danville", price: 1200 },
  { value: "VA - Richmond East", price: 1200 },
  { value: "VA - Richmond", price: 1200 },
  { value: "VA - Hampton", price: 1150 },
  { value: "VA - Fredericksburg", price: 1150 },
  { value: "WV - Charleston", price: 1575 },
  { value: "WI - Milwaukee", price: 1450 },
  { value: "WI - Appleton", price: 1400 },
  { value: "WI - Madison South", price: 1425 },
  { value: "WI - Milwaukee North", price: 1450 },
  { value: "WI - Milwaukee South", price: 1400 },
  { value: "CT - Hartford South", price: 1100 },
  { value: "DE - New Castle", price: 1150 },
  { value: "IL - Chicago West", price: 1400 },
  { value: "IL - Dream Rides", price: 1400 },
  { value: "IL - Lincoln", price: 1400 },
  { value: "IL - REC RIDES Online Exclusive", price: 1400 },
  { value: "IL - Specialty Division", price: 1400 },
  { value: "IL - St. Louis", price: 1400 },
  { value: "IN - Indianapolis South", price: 1400 },
  { value: "IN - South Bend", price: 1400 },
  { value: "MA - Boston Shirley", price: 1200 },
  { value: "MA - Taunton", price: 1200 },
  { value: "MA - Templeton", price: 1200 },
  { value: "MD - Dundalk", price: 1075 },
  { value: "MD - Elkton", price: 1075 },
  { value: "MD - Metro DC", price: 1075 },
  { value: "ME - Online Exclusive", price: 1275 },
  { value: "ME - Portland Gorham", price: 1275 },
  { value: "Ml - Grand Rapids", price: 1500 },
  { value: "MN - Minneapolis South", price: 1575 },
  { value: "MN - Minneapolis/St. Paul", price: 1575 },
  { value: "NH - Manchester", price: 1250 },
  { value: "NJ - Avenel New Jersey", price: 950 },
  { value: "NJ - Central New Jersey", price: 950 },
  { value: "NJ - Englishtown", price: 950 },
  { value: "NJ - Port Murray", price: 950 },
  { value: "NJ - Sayreville", price: 950 },
  { value: "NJ - Southern New Jersey", price: 950 },
  { value: "OH - Akron Canton", price: 1300 },
  { value: "OH - Cincinnati", price: 1300 },
  { value: "OH - Cincinnati South", price: 1300 },
  { value: "PA - Bridgeport", price: 1350 },
  { value: "PA - Erie", price: 1350 },
  { value: "PA - Pittsburgh", price: 1350 },
  { value: "PA - York Springs", price: 1350 },
  { value: "RI - Providence", price: 1300 },
  { value: "VA - Culpeper", price: 1250 },
  { value: "VA - Northern Virginia", price: 1300 },
  { value: "VA - Pulaski", price: 1250 },
  { value: "VA - Roanoke", price: 1250 },
  { value: "VA - Suffolk", price: 1250 },
  { value: "VA - Tidewater", price: 1300 },
  { value: "VT - Burlington", price: 1475 },
  { value: "WI - Portage", price: 1475 },
  { value: "WV - Buckhannon", price: 1475 },
  { value: "WV - Shady Spring", price: 1475 },
  { value: "MENTONE", price: 1725 },
  { value: "ME - WINDHAM", price: 1375 },
  { value: "MI WAYLAND", price: 1475 },
];

const DESTINATIONOPTIONS = [{ value: "Georgia, Poti", label: "Georgia, Poti" }];
const DESTINATIONPRICES = [{ value: "Georgia, Poti", price: 0 }];

const WAREHOUSEOPTIONS = [{ value: "New Jersey", label: "New Jersey" }];
const WAREHOUSEPRICES = [{ value: "New Jersey", price: 0 }];

export const Calculator = () => {
  const [auction, setAuction] = useState();
  const [location, setLocation] = useState();
  const [destination, setDestination] = useState();
  const [carType, setCarType] = useState();
  const [warehouse, setWarehouse] = useState();
  const [price, setPrice] = useState(0);

  const calculate = () => {
    const auctionPrice = AUCTIONPRICES.find((e) => e.value === auction);
    const locationPrice = LOCATIONPRICES.find((e) => e.value === location);
    const destinationPrice = DESTINATIONPRICES.find(
      (e) => e.value === destination
    );
    const warehousePrice = WAREHOUSEPRICES.find((e) => e.value === warehouse);
    const carPrice = CARPRICES.find((e) => e.value === carType);
    console.log(carPrice);

    if (auctionPrice || locationPrice || destinationPrice || carPrice) {
      setPrice(
        (auctionPrice?.price ?? 0) +
          (locationPrice?.price ?? 0) +
          (destinationPrice?.price ?? 0) +
          (warehousePrice?.price ?? 0) +
          (carPrice?.price ?? 0)
      );
    }
  };

  return (
    <div className="flex w-full border rounded-2xl p-1 shadow-2xl flex-col lg:flex-row gap-1">
      <div className="bg-img-left w-full lg:w-9/12 p-5 flex flex-col justify-between rounded-xl ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 sm:gap-5 text-white">
          <CustomSelect
            title={"აუქციონი"}
            options={AUCTIONOPTIONS}
            setter={setAuction}
          />
          <CustomSelect
            title="ავტომობილი"
            options={CAROPTIONS}
            setter={setCarType}
          />
          <CustomSelect
            title={"შტატი"}
            options={LOCATIONOPTIONS}
            setter={setLocation}
          />
          <CustomSelect
            title={"საწყობი"}
            options={WAREHOUSEOPTIONS}
            setter={setWarehouse}
          />
          <CustomSelect
            title={"საბოლოო დანიშნულება"}
            options={DESTINATIONOPTIONS}
            setter={setDestination}
          />
        </div>
        <button
          className="mt-6 bg-[#FFFFFF] border border-[#1B5E20] shadow-inner w-full p-3 text-xl rounded-full text-[#2E7D32] font-Arial font-medium hover:-translate-y-1 hover:scale-105"
          onClick={calculate}
        >
          დათვლა
        </button>
      </div>
      <div className="bg-img w-full lg:w-3/12 p-5 rounded-xl text-center text-white">
        <p>ფასი მოცემულია ფოთის პორტამდე</p>
        <span className="text-7xl text-center mt-2 mb-4">{price}$</span>
        <p className="mb-3">დეტალური ინფორმაციის მისაღებად დაგვიკავშირდით</p>
        <Flex gap={5} justifyContent={"center"}>
          <Link
            to="//api.whatsapp.com/send?phone=995555330844"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#25D366"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
            >
              <FaWhatsapp color="#fff" size={"30"} />
            </Flex>
          </Link>
          <Link
            to="//telegram.me/+995577450906"
            style={{
              width: "fit-content",
              borderRadius: "10px",
            }}
          >
            <Flex
              bg={"#229AD4"}
              width={"fit-content"}
              py={2}
              px={2}
              gap={3}
              borderRadius={10}
              alignItems={"center"}
            >
              <FaTelegram color="#fff" size={"30"} />
            </Flex>
          </Link>
        </Flex>
      </div>
    </div>
  );
};
