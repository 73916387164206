import { useState } from "react";
import vid from "../../assets/video/dockvideo.mp4";
import { CustomInput } from "../UI/Input";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";

export const ContactUs = () => {
  const [data, setData] = useState({
    name: "",
    lastName: "",
    number: "",
    message: "",
  });
  const [errors, setErrors] = useState({ number: false });

  const sendData = async (e) => {
    e.preventDefault();
    if (!data.number) {
      setErrors({ number: true });
    } else {
      setErrors({ number: false });
      try {
        await addDoc(collection(db, "contacts"), {
          name: data.name,
          lastName: data.lastName,
          number: "+995" + data.number,
          message: data.message,
          timestamp: new Date(),
        });
        toast.success("თქვენი შეტყობინება წარმატებით გაიგზავნა");
        setData({ name: "", lastname: "", number: "", message: "" });
      } catch (error) {
        console.error("Error submitting form: ", error);
      }
    }
  };

  return (
    <div className="h-[calc(100vh-64px)] overflow-hidden relative">
      <video
        className="w-full object-cover h-full"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={vid} type="video/mp4" />
      </video>
      <div className="absolute w-full h-full bg-gradient-to-b from-white via-white/70 top-0">
        <div className="flex min-h-screen justify-start">
          <div className="mx-auto w-full max-w-lg p-6 mt-24">
            <h1 className="text-xl sm:text-4xl font-medium text-center">
              დაგვიკავშირდით
            </h1>
            <p className="mt-3"></p>
            <form className="mt-10" onSubmit={sendData}>
              <div className="grid gap-6 ">
                <CustomInput
                  label={"სახელი"}
                  name={"name"}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }));
                  }}
                  value={data.name}
                  placeholder={" "}
                  type={"text"}
                />
                <CustomInput
                  label={"გვარი"}
                  name={"lastname"}
                  onChange={(e) => {
                    setData((prevData) => ({
                      ...prevData,
                      lastName: e.target.value,
                    }));
                  }}
                  value={data.lastName}
                  placeholder={" "}
                  type={"text"}
                />
                <div className="flex relative w-full">
                  <CustomInput
                    label={"ნომერი +995"}
                    name={"number"}
                    onChange={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        number: e.target.value,
                      }));
                    }}
                    value={data.number}
                    placeholder={" "}
                    type={"text"}
                  />
                  {errors.number ? (
                    <span className="absolute text-xs -bottom-4 left-0 text-red-500">
                      შეიყვანეთ მობილურის ნომერი
                    </span>
                  ) : null}
                </div>
                <div className="relative z-0">
                  <textarea
                    name="message"
                    rows="5"
                    className="peer block w-full appearance-none border-0 border-b border-gray-500 bg-transparent py-2.5 px-0 text-sm text-gray-900 focus:border-blue-600 focus:outline-none focus:ring-0"
                    onChange={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        message: e.target.value || "",
                      }));
                    }}
                    value={data.message}
                    placeholder=" "
                  ></textarea>
                  <label className="absolute top-3 -z-10 origin-[0] -translate-y-6 scale-75 transform text-sm text-gray-500 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:left-0 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-600 peer-focus:dark:text-blue-500">
                    თქვენი მესიჯი
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="mt-5 rounded-md bg-black px-10 py-2 text-white"
              >
                გაგზავნა
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
