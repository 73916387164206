import PropTypes from "prop-types";
import Select from "react-select";

export const CustomSelect = ({ title, options, setter }) => {
  return (
    <div className="flex items-center flex-col lg:flex-row">
      <span className="lg:w-1/3 text-xl mb-2 sm:mb-0 text-center font-Arial font-semibold lg:text-start">
        {title}
      </span>
      <Select
        placeholder="აირჩიე"
        className="w-full lg:w-2/3 text-black"
        onChange={(e) => setter(e.value)}
        options={options}
      />
    </div>
  );
};

CustomSelect.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};
